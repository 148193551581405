import * as React from "react"
import { HeadFC } from "gatsby"
import { Center, Heading } from "@chakra-ui/react"

const NotFoundPage = () => {
    return (
        <Center h='100vh'>
            <Heading>Page not found</Heading>
        </Center>
    )
}

export default NotFoundPage

export const Head: HeadFC = () => <title>Not found</title>
